<template lang="">
    <ul class="user_info rounded bg-white shadow py-4 m-auto">
        <h2 class="text-center default user_info-title m-0"><img src="@/assets/icons/user.svg" alt="" class="w-100" /><br /><span>Informations sur le patient</span></h2><br>
        <ul class="row list-none user_info-content pl-0">
            <li class="d-flex mt-3 col-md-4"><span><i class="fa-solid fa-user text-secondary"></i>Nom :</span> <span class="span">{{truncate(patient?.user?.nom,20)}}</span></li>
            <li class="d-flex mt-3 col-md-4"><span><i class="fa-solid fa-user text-secondary"></i>Prénom : </span> <span class="span">{{patient?.user?.prenom}}</span></li>
            <li class="d-flex mt-3 col-md-4"><span><i class="fa-solid fa-calendar-days text-secondary"></i>Date de naissance :</span> <span class="span">{{moment(patient?.date_de_naissance).format('DD-MM-YYYY')}}</span></li>
            <li class="d-flex mt-3 col-md-4"><span><i class="fa-solid fa-calendar-days text-secondary"></i>Age :</span> <span class="span">{{patient?.age}} ans</span></li>
            <li class="d-flex mt-3 col-md-4"><span><i class="fa-solid fa-address-book text-secondary"></i>Adresse :</span><span class="span">{{patient?.user?.adresse}}</span></li>
            <li class="d-flex mt-3 col-md-4"><span><i class="fa-solid fa-person text-secondary"></i>Sexe :</span><span class="span">{{patient?.sexe}}</span></li>
        </ul>
    </ul>
</template>
<script setup>
import moment from "moment";
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore();
const truncate = (str, n) =>{
            return (str.length > n) ? str.slice(0, n-1) + '...' : str;
        }
const patient =  computed(() => store.state.patient.select);
</script>