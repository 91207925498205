<template lang="">
    <div class="py-4 bg-white px-3 rounded-1 shadow dark prescription">
        <div class="d-flex justify-content-between">
            <div><h4 class="">Nouveau Message</h4></div>
            <div>
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="service"
                    :options="services"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options"
                ></b-form-radio-group>
            </div>
        </div>
        <hr />
        <div v-if="service == 1">
            <form action="" class="prescription-form">
                <div class="row my-4">
                    <div class="col-md-6 d-block">
                            <span class="text-danger font-weight-bold">*</span><label class="default">Email de l'utilisateur concerné</label>
                            <div>
                                <div class="w-100 d-block d-md-flex align-items-center" v-if="store.searchData">
                                    <VueMultiselect 
                                        v-model="state.user" 
                                        :options="first" 
                                        :multiple="true" 
                                        @search-change="DataPatient"
                                        placeholder="Rechercher un Patient" 
                                        track-by="email" label="email"
                                        >
                                    </VueMultiselect>
                                </div>
                                <small v-if="v$.user.$error" class="red list-none ">
                                    {{ "Entrer un utilisateur" }}
                                </small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <span class="text-danger font-weight-bold">*</span><label class="default">Objet du message</label>
                        <div>
                            <input type="text" class="form-control quantity" v-model="state.subject">
                        </div>
                        <small v-if="v$.subject.$error" class="red list-none ">
                            {{ "Entrer un objet" }}
                        </small>
                    </div>
                </div>
                <div class="mb-4">
                    <span class="text-danger font-weight-bold">*</span><label class="typo__label default">Votre message :</label>
                        <div>
                            <Editor api-key='mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn'
                                :init="{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help'
                                }"
                                v-model="state.msge"
                            />
                            <small v-if="v$.msge.$error" class="red list-none ">
                                {{ "Entrer un message" }}
                            </small>
                        </div>
                </div>
                <div class="d-flex justify-content-md-end my-4">
                    <span @click="submitForm" :disabled="!validation" :class="!validation ? 'btn btn-light p-2 px-4' : 'btn btn-primary p-2 px-4'">Envoyer</span>
                </div>
            </form>
        </div>
        <div v-if="service == 2">
            <form action="" class="prescription-form">
                <div class="row my-4">
                    <div class="col-md-6 d-block">
                            <span class="text-danger font-weight-bold">*</span><label class="default">Numero de l'utilisateur concerné</label>
                            <div>
                                <div class="w-100 d-block d-md-flex align-items-center" v-if="store.searchData">
                                    <VueMultiselect 
                                        v-model="state.user" 
                                        :options="first" 
                                        :multiple="true" 
                                        @search-change="numberPatient"
                                        placeholder="Rechercher un Patient" 
                                        track-by="tel" label="tel"
                                        >
                                    </VueMultiselect>
                                </div>
                                <small v-if="v$.user.$error" class="red list-none ">
                                    {{ "Entrer un utilisateur" }}
                                </small>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <span class="text-danger font-weight-bold">*</span><label class="typo__label default">Votre message :</label>
                        <div>
                            <Editor api-key='mm3cmjvl9px5ksezprcy72un7g456ii25fpje4frqemgkrqn'
                                :init="{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help'
                                }"
                                v-model="state.msge"
                            />
                            <small v-if="v$.msge.$error" class="red list-none ">
                                {{ "Entrer un message" }}
                            </small>
                        </div>
                </div>
                <div class="d-flex justify-content-md-end my-4">
                    <span @click="submitForm" :disabled="!validation" :class="!validation ? 'btn btn-light p-2 px-4' : 'btn btn-primary p-2 px-4'">Envoyer</span>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import AdministrativeData from "@/components/organisms/AdministrativeData.vue";
import VueMultiselect from 'vue-multiselect';
import * as ECT from '@whoicd/icd11ect';
import { useVuelidate } from '@vuelidate/core'
import '@whoicd/icd11ect/style.css';
import Modal from "@/views/Admin/Teleconsultation/Modal.vue";
import Editor from '@tinymce/tinymce-vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { required } from '@vuelidate/validators'
import MultiStepsForm from '@/components/form/MultiStepsForm.vue';
import { useRouter, useRoute } from 'vue-router';
import { ref, reactive, inject,onMounted, computed, watch } from 'vue';
import { useEmailsms } from "@/_storeV2/email-sms";
import Datepicker from '@vuepic/vue-datepicker';

const swal = inject('$swal');
const loading = computed(() => store.getLoading);
const store = useEmailsms();
const xstore = useStore();
const router = useRouter();
const route = useRoute();
// Globale
const props = defineProps({
    state: {},
    v$: {}
})
const Status = ref(false)
const steps = reactive([
    { title: 'Documentation', slotName: 'step1' },
    { title: 'Données', slotName: 'step2' },
    // { title: 'Examen(s)', slotName: 'step3' },
])
const PlainteRules = computed(() => {
    return {
        description: {required},
        type: {required},
        date: { required }
    }
})
const services = ref([
    { value: 1, text: 'Email', description: 'Email' },
    { value: 2, text: 'Sms', description: 'SMS' },
])
const users = ref([
    { id: 1, name: 'Paul', language: 'Heures' },
    { id: 2, name: 'Piere', language: 'Jours' },
    { id: 3, name: 'Jean', language: 'Semaines' },
    { id: 4, name: 'Marie', language: 'Mois' },
    { id: 5, name: 'Aude', language: 'Année' },
])
const dataEtablissement = computed(() => {
    return xstore.state.etablissement.etablissements?.data.map((item) => 
    {
        return {
            id: item.id,
            name: item.name
        }
    }
)
})
    const first = ref([])
    const patientSelect = ref(false)
    const valid = ref(true)
    const service = ref(1)
    const doc = ref(true)
    const elements = ref(false)
    const dialogVisible = ref( false)
    const height = ref(200)
    const value = ref([])
    const maladie = ref([])
    const dataExamenPush = ref([])
    let data = ref([])
    const categorie = ref('')
    const horaire = ref('')
    const evenement = ref('')
    const user = ref([]);
    const searchData= ref([]);

    const getEtab = computed(() => {return store.getOnePrescription.etablissements || []})
    const medicamentData = computed(() => { return store.getOnePrescription.medicaments || [] })
    const user_id = computed(() => xstore.state.users.user.id);
    const medecin_id = xstore.state.users.user.id;
    const patient_id = xstore.state.patient.select.user_id;

    let option_financement = ref([])
    let etablissements= ref({})
    let option_financemt = ref({})
    let raison_presc = ref({})
    let niveau_urg = ref({})
    let ligne_temps = ref({})
    let raison = ref({})

    const motifs = computed(() => {
        raison.value={id: getMotif.value}
        return raison.value
    })
    const AvoidSubmitForm = () => {
        swal.fire({
            title: 'Voulez-vous vraiment quitter cette page?',
            text: "Vous n'avez pas terminé la saisie de ce formulaire",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#32325d',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, quitter',
            cancelButtonText: 'Non, annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                router.go(-1)
            }
        })
    }

    //truncate word
    const truncate = (word, maxLength) => {
        if (word.length > maxLength) {
            return word.slice(1, maxLength) + '...';
        }
        return word;
    }

    const state = reactive({
        user : [],
        subject: '',
        msge: '',
        isLoadingEtablissement: false,
        medecin_id: medecin_id,
    });

    const rules = reactive({
        user: { required },
        subject: { required },
        msge: { required },
    });
const v$ = useVuelidate(rules, state);
    v$.value.$touch();
    const validation = computed(() => {
    if (!v$.value.user.$error 
        && !v$.value.subject.$error 
        && !v$.value.msge.$error
        ) 
        {
        valid.value = false
        return true
    }else{
        valid.value = true
        return false
    } 
})
const submitForm = () => {
    console.log( 'vss', v$.value)
    v$.value.$touch();
    v$.value.$validate()
    if (validation.value){
        store.createEmailsms(state);}
        else {
            console.log('false',v$)
        }
                // Status.value = store.loading;
           }
    const CategorieMedicamenteuse =  ref([])
    const CategorieMedicamenteuses = async () =>{
        await PrescriptionService.getCategorieMedicamenteuses()
            .then(response => {
                CategorieMedicamenteuse.value = response.data.data
            })
            .catch(error => {
                console.log(error);
            });
    }
    const UnitePresentation =  ref([])
    const UnitePresentations = async () =>{
        await PrescriptionService.getUnitePresentations()
            .then(response => {
                UnitePresentation.value = response.data.data
            })
            .catch(error => {
                console.log(error);
            });
    }
    const DataPatient = (user) => {   // isLoading.value = true;
        store.searchPatient(user);
            first.value = store.searchData.data.map((item) => {
                return {
                  email: item.email
                //   nom: item.nom,
                //   prenom: item.prenom,
                //   name: item.name,
                //   user_id: item.id,
                };
              })
    }
    const numberPatient = (user) => {   // isLoading.value = true;
        store.searchPatient(user);
            first.value = store.searchData.data.map((item) => {
                return {
                  tel: item.telephone
                };
              })
    }
    const confirmPatient = (patient) => {
        patientSelect.value = true
    }
const asyncFindEtablissement = (query) => {
    state.isLoadingEtablissement = true
    xstore.dispatch("etablissement/searchEtablissements", {search: query, page:  1}).then(() => {
        state.isLoadingEtablissement = false;
    });
}

onMounted(() => {
    if(localStorage.getItem('medicament')){
        data.value = JSON.parse(localStorage.getItem('medicament')) || [];
    }
    xstore.dispatch("etablissement/fetchEtablissements");
    if(route.params.uuid !== undefined){
        store.getPrescriptionId(route.params.uuid);
    }


  })
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>