<template lang="">
    <div class="row">
        <div class="row bg-white rounded shadow px-4 pb-5 mt-4 pt-1">
            <div class="d-md-flex justify-content-md-between mt-4 mb-1">
                <a @click="$router.go(-1)" class="back"><i class="fa fa-arrow-left pb-3 cursor-pointer" aria-hidden="true"></i></a>
                <h5 class="fwb default">Détails du bulletin d'examens d'analyses biomédicales AB #{{ store.getOneBulletin.id }}</h5>
                <!-- <a v-if="teleconsultation?.url" :href="teleconsultation?.url" target="_blank"><i class="fa-solid fa-circle-play mx-1 cursor-pointer fa-2xl"></i></a>-->

                <button type="button" class="btn text-primary fwb" v-can="'Imprimer un bulletin d\'examen'"
                @click="printBulletin(store.getOneBulletin.pdf)"><i class="fa fa-print ml-2"></i> Imprimer</button>
            </div>
            <div class="d-flex justify-content-end" v-if="store.getOneBulletin.patient?.user">
                <div class="p-2" v-can="'Partager un bulletin d\'examen'">
                    Envoyer par
                </div>
                <!-- <div class="p-2">
                    <ShareNetwork
                        network="email"
                        :url="store.getOneBulletin.pdf"
                        :title="'Hello, consulter le  bulletin d\'examen d\'analyse biomédicale *AB #'+store.getOneBulletin.id+'* du patient *'+store.getOneBulletin.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-solid fa-envelope fa-2x" style="color: #172b4d;"></i>
                    </ShareNetwork>
                </div> -->
                <div class="p-2" v-can="'Partager un bulletin d\'examen'">
                    <ShareNetwork
                        network="telegram"
                        :url="store.getOneBulletin.pdf"
                        :title="'Hello, consulter le  bulletin d\'examen d\'analyse biomédicale *AB #'+store.getOneBulletin.id+'* du patient *'+store.getOneBulletin.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-brands fa-telegram fa-2x" style="color: #0088cc;"></i>
                    </ShareNetwork>
                </div>
                <div class="p-2" v-can="'Partager un bulletin d\'examen'">
                    <ShareNetwork
                        network="whatsapp"
                        :url="store.getOneBulletin.pdf"
                        :title="'Hello, consulter le  bulletin d\'examen d\'analyse biomédicale *AB #'+store.getOneBulletin.id+'* du patient *'+store.getOneBulletin.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-brands fa-whatsapp fa-2x" style="color: #128C7E;"></i>
                    </ShareNetwork>
                </div>
            </div>
            <div class="d-flex mt-4" v-if="store.loading">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin m-auto"/>
            </div>
            <div v-if="!store.loading">
                <div class="mt-3">
                    <fieldset class="form-group border p-2">
                        <legend class="default float-none w-auto px-2 h2">Documentations</legend>
                            <div class="boxDetails w-100">
                                <div class="row pt-2">
                                    <div class="col-md-6">
                                        <p class="mb-4">
                                            <span class=" my-2 default fwb"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Evénement Médical associé :</span> <div class="pt-1">{{store.getOneBulletin.ligne_temps?.description}}</div>
                                        </p>
                                        <p>
                                            <div>
                                                <span class=" mb-1 mt-2 default fwb"><img src="@/assets/icons/DetailTeleconsultation/save-money.png" alt="" class="img-alert"/>Option de financement :</span>
                                            </div>
                                            <div class="mx-3">
                                            <div class="mt-2 d-inline-flex" v-for="finance in store.getOneBulletin.option_financements"><span class="bgDetails py-1 px-2 mx-1">
                                                    {{finance.libelle}}
                                                </span></div>
                                            </div>
                                        </p>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="mb-4">
                                            <span class=" m-1 my-2 default fwb"><img src="@/assets/icons/DetailTeleconsultation/medical-service.png" alt="" class="img-alert"/>Niveau d'urgence :</span>
                                            <span class="mt-1 mx-2">{{store.getOneBulletin.niveau_urgence_id}}</span>
                                        </p>
                                        <p>
                                            <div>
                                                <span class=" mb-1 mt-2 default fwb"><img src="@/assets/icons/DetailTeleconsultation/prescription.png" alt="" class="img-alert"/>Raison de la Prescription :</span>
                                            </div>
                                            <div class="mx-3">
                                            <div class="mt-2 d-inline-flex" v-for="raison in store.getOneBulletin.raison_prescriptions"><span class="bgDetails py-1 px-2 mx-1">
                                                    {{raison.libelle}}
                                                </span></div>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                    </fieldset>
                </div>
                <div class="mt-5">
                    <fieldset class="form-group border p-2">
                        <legend class="default float-none w-auto px-2 h2">Données Variables</legend>
                            <div class="boxDetails w-100">
                                <div class="row pt-2 ml-2">
                                    <div class=" mb-1">
                                        <div class="mb-4">
                                            <span class=" default fwb"><img src="@/assets/icons/DetailTeleconsultation/examination.png" alt="" class="img-alert"/>Etablissement
                                            </span>
                                            <div class="mt-2" v-for="exam in store.getOneBulletin.etablissements">
                                                <span>
                                                    {{exam.name}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mb-4"><span class="default fwb"><img src="@/assets/icons/DetailTeleconsultation/medical-record.png" alt="" class="img-alert"/>Renseignement cliniques du patient</span>
                                            <div class="mt-2">
                                                <span v-html="store.getOneBulletin.renseignement_clinique"></span>
                                            </div>
                                        </div>
                                        <div class="mb-4">
                                            <div>
                                                <span class="default fwb"><img src="@/assets/icons/DetailTeleconsultation/examination.png" alt="" class="img-alert"/>Examen(s) a réaliser 
                                                </span>
                                            </div>
                                            <div class="d-inline-flex mt-2" v-for="exam in store.getOneBulletin.examen_complementaires">
                                                <span class="bgDetails px-2 mx-1">
                                                    {{exam.fr_description}}
                                                </span>
                                            </div>
                                            <div class="pt-1 w-100">
                                                <div class="position-absolute top-50 start-50" v-if="loader">
                                                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                                                </div>
                                            <div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="mb-4" v-if="store?.getOneBulletin?.teleconsultations?.length > 0"><span class="default fwb"><img src="@/assets/icons/DetailTeleconsultation/medical.png" alt="" class="img-alert"/>Explication de la demande de diagnostic</span>
                                    <div class="mt-2" v-for="tele in store.getOneBulletin.teleconsultations">
                                                <span>
                                                    {{tele.description_diagnostic}}
                                                </span>
                                            </div>
                                    </div>
                                    <div class="mb-4"><span class="default fwb"><img src="@/assets/icons/DetailTeleconsultation/deadline.png" alt="" class="img-alert"/>Date</span>
                                        <div class="mt-2">
                                            <span>
                                                {{store.getOneBulletin.date_heure}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div v-for="doctor in store.getOneBulletin.medecin" class="my-3">
                <span class="my-3">
                    {{doctor.name}}
                </span>
                <div>
                    <span class="">
                        Téléphone : 
                        <span class="">
                            {{doctor.user?.telephone}}
                        </span>
                    </span>
                </div>
                <div>
                    <span class="">
                        Numero d'ordre : 
                        <span class="">
                            {{doctor.numero_ordre}}
                        </span>
                    </span>
                </div>
                <div v-if="doctor?.user?.signature">
                   <img :src="doctor?.user?.signature" class="img-fluid w-25"/>
                </div>
            </div>
                <div class="d-md-flex justify-content-md-end" v-can="'Modifier un bulletin d\'examen'">
                    <div class="mb-2 mt-2 mx-2" v-can="'Modifier un bulletin d\'examen'">
                        <button @click="openShow(store.getOneBulletin)" size="sm" class="btn btn-dark px-2 fw-bold text-h5 w-100">
                            <i class="fa fa-edit px-1"></i><span class="px-1">Modifier</span>
                        </button>
                    </div>
                    <div class="mb-2 mt-2 mx-2" v-can="'Supprimer un bulletin d\'examen'">
                        <button  @click="deleteBulletin(store.getOneBulletin.id)" size="sm" class="btn btn-outline-danger px-2 fw-bold text-h5 w-100">
                            <i class="fa fa-trash px-1"></i><span class="px-1">Supprimer</span>
                        </button>
                    </div>
                </div>
        </div>

    </div>

</template>
<script setup>
import { useStore } from 'vuex';
import moment from "moment";
import { computed, onMounted } from 'vue';
import UserInfo from '@/components/organisms/UserInfo.vue';
import ExamenComplementaireModule from '../../../store/modules/examen/index';
import AddPrescription from '@/views/Admin/ePrescription/AddPrescription.vue';
import { useBulletin } from '../../../_storeV2/bulletin/index';
import { useRouter, useRoute } from 'vue-router';
import { inject } from 'vue';
const swal = inject('$swal');

const store = useBulletin();
const route = useRouter();
const router = useRoute();
const bulletin = computed(() => store.bulletin);
// Globale
const props = defineProps({
  bulletin: {},
})

const printBulletin = (bulletin_pdf) => {
        window.open(bulletin_pdf, '_blank');
}
function openShow(bulletin) {
        // useStore.getBulletinId(bulletin.uuid);
        route.push({
            name: 'modification-bulletin-examen' ,
            params: {'uuid': bulletin.uuid},
        })
    }
    const deleteBulletin=(item_id)=>{
        swal.fire({
        title: 'Voulez-vous vraiment supprimer ce Bulletin?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    })
    .then((result) => {
        if (result.isDenied) {
            store.deleteBulletin(item_id).then(() => {
                route.push({
                    name: 'bulletin-examen' ,
                })
                store.fetchBulletins(1, "", 5); 
            });
        }
    })
    }
const convertJeson = data => {
    if(data != undefined){
        return JSON.parse(data).anamnese
    }
    return ""
}
onMounted(() => {
    store.getBulletinId(router.params.uuid);
})
</script>