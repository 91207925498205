import { defineStore } from "pinia";
import EmailSmsService from "@/_services/EmailSmsService"; 
import { useToast } from 'vue-toast-notification';
import router from "@/router";

export interface Emailsms {
    id: number;
    libelle: string;
    data: any;
}


export const useEmailsms = defineStore({
    id: 'analyse_medical',
    state: () => ({
        $toast: useToast(),
        emailsmss: {
            data: []
        } as Emailsms,
        searchData: {
            data: []
        } as Emailsms,
        emailsms: {} as Emailsms[] | null,
        loading: false
    }),
    getters: {
        getEmailsms() : Object{
            return this.emailsmss;
        },
        getUsers() : Object{
            return this.searchData;
        },
        getOneEmailsms() : Object | any {
            return this.emailsms;
        },
    },
    actions: {
        // Actions

        async fetchEmailsmss(paginate = {page: 1, page_size: 5, search: ""}) {
            this.loading = true
            await EmailSmsService.getEmailsmss(paginate.page, paginate.page_size, paginate.search)
                .then((res: any) => {
                    this.loading = false
                    this.emailsmss = res.data
                    console.log('this.emailsmss',this.emailsmss)
                })
                .catch((err:any) => console.log(err))
        },
        async searchPatient(find: ""){
            await EmailSmsService.searchPatient(find).then((res: any) => {
              const patientData: any = res.data.users
              this.searchData = patientData
            })},
        async getEmailsmsId(uuid: any) {
            // this.loading = true
            // this.emailsms = this.emailsmss.data.data.find(( uuid: any ) => uuid === '5838614c-55f6-4ac2-84fe-f78ef9710017')
            // console.log('this.emailsms',this.emailsms)
            await EmailSmsService.getEmailsms(uuid).then((res: any) => {
                this.loading = false
                this.emailsms = res.data
            })
            .catch((err:any) => console.log(err));
            // this.emailsmss = this.emailsmss.filter(emailsms => emailsms.id == id);
        },

        async deleteEmailsms(id: any) {
            this.loading = true
            await EmailSmsService.deleteEmailsms(id).then((res: any) => {
                this.loading = false
                this.$toast.warning('Emailsms supprimée avec succès', { position: 'top-right' });
            })
            .catch((err:any) => {
                this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                console.log(err)
            });
        },

        async printEmailsms(id: any) {
            await EmailSmsService.printEmailsms(id).then((res: any) => {
                console.log(id);
                // this.fetchEmailsmss({page: 1, page_size: 5})
            })
            .catch((err:any) => console.log(err));
        },

        async createEmailsms(emailsms: any) {
            const emailsmss = {
                subject: emailsms.subject,
                message: emailsms.msge,
                Creator: emailsms.medecin_id,
                email: emailsms.user.map((item: any )=> 
                   { return item.email
                    }
                  )
            }
            this.loading = true
            await EmailSmsService.createEmailsms(emailsmss)
                .then((res: any) => {
                    this.loading = false
                    this.emailsmss = res.data.data
                    this.$toast.success('Emailsms créé avec succès', { position: 'top-right' })
                    router.push({name: 'email-sms-affichage', params: {'uuid': 'datauuid'}})
                    // router.push({name: 'email-sms-affichage', params: {uuid: res.data.data?.uuid}})
                })
                .catch((err:any) => {
                    this.loading = false
                    this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                    console.log(err)
                })
        },

        async updateEmailsms(uuid:string, emailsms: any) {
            const emailsmss = {
                objet_id: emailsms.objet.id,
                message_id: emailsms.message.id,
                medecin_id: emailsms.medecin_id,
                patient_id: emailsms.patient_id,
                date_heure: emailsms.date_heure.id,
            }
            this.loading = true
            await EmailSmsService.updateEmailsms(uuid, emailsmss)
                .then((res: any) => {
                    this.loading = false
                    this.emailsmss = res.data.data
                    this.$toast.success('Emailsms modifié avec succès', { position: 'top-right' })
                    localStorage.removeItem('examen')
                    router.push({name: 'emailsms-affichage', params: {uuid: res.data.data?.uuid}})
                })
                .catch((err:any) => {
                    this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                    console.log(err)
                })
        }
    }
});