<template lang="">
    <div class="my-5">
       <div class="mb-5">
            <div class="card-body" v-can="'Créer une téléconsultation'">
                <AddTeleconsultation/>
            </div>
       </div> 

       <!-- <loader-data :loading="loading" /> -->
       <div class="py-3">
            <div class="pb-3 default">
                <h4 class="text-h2 fw-bold">Listing des téléconsultations effectuées</h4>
            </div>
            <Dynamic-Modal :show="signature" @close="signature = false" title="Ajouter votre signature numérique" class="signature">
                <div class="m-2 default">
                    <p>
                        Veuillez insérer votre signature ci‑après pour qu'elle figure dans vos rapports de téléconsultation.
                    </p>
                </div>
                <div class="border m-2">
                    <Vue3Signature ref="signature1" :w="'100%'" :h="'400px'" 
                    class="example"></Vue3Signature>
                </div>
                <template #footer>
                    <div class="d-flex justify-content-center gap-1 py-4 px-2">
                        <button class="btn btn-primary" @click="save()">Enregistrer</button>
                        <button class="btn btn-dark" @click="clear">Vider</button>
                        <button class="btn border" @click="undo">Revenir</button>
                    </div>
                </template>
            </Dynamic-Modal>
        <div class="card border-0 pb-0 px-2 pt-2 mt-3 d-none d-md-block" v-if="paginations">
            <div class="table-responsive">
            <!-- TEST for table pagination -->
             <!--Complete Table vue bootstrap template-->
                <div class="container">
                    <div>
                        <ul class="list-none p-0 mt-2 d-md-flex align-items-center gap-2 float-md-end">
                            <div class="d-flex align-items-center justify-content-between gap-1">
                                <li class="">
                                    <select class="form-select" v-model="page_size" @change="changeSize" aria-label="Default select example">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </li>
                            </div>
                            <li class="mt-2 mt-md-0"><input type="text" v-model="search" @keyup="searchTeleconsultation($event)" class="form-control" placeholder="rechercher..."></li>
                        </ul>
                    </div>
                    <div v-if="loading" class="container my-3 d-flex justify-content-center">
                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                    </div>
                    <table class="table table-striped" v-if="!loading">
                        <thead>
                            <tr class="default">
                                <th class="py-3">Patient</th>
                                <th class="text-left py-3">Motifs</th>
                                <th class="text-left py-3">Date</th>
                                <th v-can="'Afficher une téléconsultation'">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="default" v-for="teleconsultation in paginations.data.data" :key="teleconsultation.id">
                                <td  class="pt-3">{{ getPatient(teleconsultation.patient) }}
                                    <span class="badge bg-secondary ml-2" @click="getAlerte(teleconsultation.alerte?.uuid)" v-if="teleconsultation.alerte?.uuid">alerte</span>
                                    <a v-if="teleconsultation?.url" :href="teleconsultation?.url" target="_blank"><i class="fa-solid fa-circle-play mx-1 cursor-pointer"></i></a>
                                </td>
                                <td class="text-left pt-3">
                                <ul class="list-none m-0 p-0">
                                    <li class="bg-none" v-for="motif in teleconsultation.motifs.slice(0, 1)">
                                        {{ motif.description }}...
                                    </li>
                                </ul>
                                </td>
                                <td class="text-left pt-3">{{ moment(teleconsultation.date_heure).format("YYYY-MM-DD HH:mm") }}</td>
                                <td class="" v-can="'Afficher une téléconsultation'">
                                    <b-button @click="SelectPatient(teleconsultation)" class="text-primary">
                                        <i class="fa fa-eye cursor-pointer px-2"></i>Voir
                                    </b-button>

                                    <b-button @click="SelectPatient(teleconsultation, true)" class="text-secondary">
                                        <i class="fa fa-edit cursor-pointer px-2"></i>
                                    </b-button>
                                    <b-button @click="deleteTeleconsultation(teleconsultation.id)" class="text-danger">
                                        <i class="fa fa-trash cursor-pointer px-2"></i>
                                    </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
            <nav aria-label="Page navigation example" v-if="paginations.data.last_page > 1">
                <ul class="pagination justify-content-center">
                    <li :class="`page-item ${paginations.data.current_page == paginations.data.from ? 'disabled' : ''}`"><a class="page-link" href="#" @click="previous(paginations.data.current_page)" tabindex="-1" aria-disabled="true">Previous</a></li>
                    <li :class="`page-item ${paginations.data.current_page == n ? 'active' : ''}`" v-for="n in paginations.data.last_page" @click="changePage(n)"><a class="page-link" href="#">{{ n }}</a></li>
                    <li class="page-item">
                        <li :class="`page-item ${paginations.data.current_page == paginations.data.last_page ? 'disabled' : ''}`"><a class="page-link" href="#" @click="next(paginations.data.current_page)" tabindex="-1" aria-disabled="true">Next</a></li>
                    </li>
                </ul>
            </nav>
        </div>

        </div>

        <div class="d-block d-md-none m-auto" v-if="paginations">
                    <div>
                        <ul class="list-none p-0 d-md-flex align-items-center gap-2 float-md-end">
                            <div class="d-flex align-items-center justify-content-between gap-1">
                                <li class="">
                                    <select class="form-select" v-model="page_size" @change="changeSize" aria-label="Default select example">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </li>
                            </div>
                            <!-- <li class="mt-2 mt-md-0"><input type="text" class="form-control" placeholder="rechercher..."></li> -->
                            <li class="mt-2 mt-md-0"><input type="text" v-model="search" @keyup="searchTeleconsultation($event)" class="form-control" placeholder="rechercher..."></li>
                        </ul>
                    </div>
                    <div  v-for="teleconsultation in paginations.data.data" :key="teleconsultation.id" class="list-group list-group-flush">
                        <div class="d-flex rounded flex-row justify-content-between w-100 p-1 my-1 list-group-item" v-b-toggle="'collapse'+teleconsultation.id">
                            <div class="" >
                                <span for="" class="my-2 text-h5 fw-semibold">Nom patient:</span>
                                <h6 class=" colorGrey text-uppercase pt-1 text-h5">{{ getPatient(teleconsultation.patient) }}</h6>
                            </div>
                            <div class="when-closed">
                                <i class="fa fa-angle-down px-2 text-dark text-h5"></i>
                            </div>
                            <div class="when-open">
                                <i class="fa fa-angle-right px-2 text-dark text-h5 "></i>
                            </div>
                        </div>
                        <b-collapse :id="'collapse'+teleconsultation.id" class="mb-2 w-100">
                            <b-card>
                                <div class="row">
                                    <p ><span class="col-7 text-h5 fw-semibold">Motif téléconsultation:</span><span class="col-5"> <label for="" class=" text-h5"></label></span></p>
                                </div>
                                <div class="row ">
                                    <ul class="row py-1 list-none">
                                        <li v-for="motif in teleconsultation.motifs">{{ motif.description }}</li>
                                    </ul>
                                </div>
                                <p class="row py-1"><span class="col-7 text-h5 fw-semibold">Date téléconsultation:</span><span class="col-5"> 
                                    <label for="" class=" text-h5">{{ teleconsultation.date_heure }}</label></span>
                                </p>

                                <div class="d-flex mt-2 justify-content-between" v-can="'Afficher une téléconsultation'">
                                    <b-button @click="SelectPatient(teleconsultation)"  size="sm" class="btn btn-success px-2 fw-bold text-h5">
                                        <i class="fa fa-eye px-1"></i><span class="px-1">Voir </span>
                                    </b-button>
                                    <b-button @click="SelectPatient(teleconsultation, true)"  size="sm" class="btn btn-secondary ml-3 px-2 fw-bold text-h5" v-can="'Modifier une téléconsultation'">
                                        <i class="fa fa-edit px-1"></i><span class="px-1"> Editer</span>
                                    </b-button>
                                </div>
                            </b-card>
                        </b-collapse>
                    </div>
                    <nav aria-label="Page navigation example" v-if="paginations.data.last_page > 1">
                        <ul class="pagination pagination-sm justify-content-center">
                            <li :class="`page-item ${paginations.data.current_page == paginations.data.from ? 'disabled' : ''}`"><a class="page-link" href="#" @click="previous(paginations.data.current_page)" tabindex="-1" aria-disabled="true">Previous</a></li>
                            <li :class="`page-item ${paginations.data.current_page == n ? 'active' : ''}`" v-for="n in paginations.data.last_page" @click="changePage(n)"><a class="page-link" href="#">{{ n }}</a></li>
                            <li class="page-item">
                                <li :class="`page-item ${paginations.data.current_page == paginations.data.last_page ? 'disabled' : ''}`"><a class="page-link" href="#" @click="next(paginations.data.current_page)" tabindex="-1" aria-disabled="true">Next</a></li>
                            </li>
                        </ul>
                    </nav>
              </div>
        </div>
</template>
<script setup>
import LoaderData from '@/components/molecules/LoaderData.vue'
import AddTeleconsultation from './AddTeleconsultation.vue';
import { useStore } from 'vuex';
import Vue3Signature from "vue3-signature"
import Modal from "./Modal.vue"
import DynamicModal from "@/components/organisms/DynamicModal.vue"
import { onMounted, computed, ref, inject } from 'vue';

import PatienService from '@/_services/PatientService';

import axios from 'axios';

import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'vue-toast-notification';

import moment from "moment";

const $toast = useToast();

const store = useStore();
const router = useRouter();

const currentPage = ref(1);
const rowsPerPage = ref(5);

const disabled = false;


const paginations = computed(() => store.state.teleconsultations.paginations)
const loading = computed(() => store.state.teleconsultations.loadingStatus)

const teleconsultation = computed(() => store.state.teleconsultations.teleconsultation)
const dossier_medical_id = computed(() => {
    return store.state.teleconsultations.paginations?.data.data.map(i => {
        return {
            dossier_medical_id: i.patient?.dossier.id
        }
    })
})
const modalView = ref(false)
const getPatient = patient => patient?.user?.prenom + ' ' + patient?.user?.nom

//Methods for Modals
const moreInfo = ref(false)

const page_size = ref(10);

const newAnt = ref(false)
const swal = inject('$swal');

//Function for List Modal
function viewMoreInfo(teleconsultation) {
    store.dispatch("teleconsultations/fetchTeleconsultation", teleconsultation);
    moreInfo.value = true;
}

const viewAntecedant = (teleconsultation) =>{
    store.dispatch("teleconsultations/fetchTeleconsultation", teleconsultation);
    newAnt.value = ref(true)
}

const deleteTeleconsultation = (teleconsultation_id) => {
    swal.fire({
        title: 'Voulez-vous vraiment supprimer cette téléconsultation ?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    }).then((result) => {
        if (result.isDenied) {
            store.dispatch("teleconsultations/delete", teleconsultation_id).then(() => {
                store.dispatch("teleconsultations/fetchTeleconsultations")
            });
        }
    })
}

const datas = "user";
function SelectPatient(teleconsultation, edition = false, associations = "user") {
    //store.dispatch("teleconsultations/fetchTeleconsultation", teleconsultation);
    store.dispatch('patient/fetchPatient', { id: teleconsultation.patient_id, associations: associations });

    router.push({
        name: edition ? 'teleconsultation-detail' : 'teleconsultation-affichage',
        params: {'uuid': teleconsultation.uuid},
    })
}

const signature = ref(null)
if(store.state.users.user?.signature != null){
    signature.value = false
}else{
    signature.value = true;
}

const user_id = store.state.users?.user?.id;
const signature1 = ref(null);
const search = ref('');
function save() {
    if(!signature1.value.isEmpty()){
        store.dispatch("users/saveSignature", {signature: signature1.value.save(), id: user_id}).then(() =>{
            signature.value = false
        })
    }else{
        $toast.warning('Veillez entrer votre signature', { position: "top-right" })
    }
}

function clear() {
    signature1.value.clear();
}
function undo() {
    signature1.value.undo();
}
const changePage = page => {
    store.dispatch("teleconsultations/fetchTeleconsultations", { page: page, page_size: page_size.value, search: search.value });
}

const previous = page => {
    page--;
    store.dispatch("teleconsultations/fetchTeleconsultations", { page: page, page_size: page_size.value, search: search.value });
}

const next = page => {
    page++;
    store.dispatch("teleconsultations/fetchTeleconsultations", { page: page, page_size: page_size.value, search: search.value });
}

const changeSize = () => {
    store.dispatch("teleconsultations/fetchTeleconsultations", { page: 1, page_size: page_size.value, search: search.value });
}

const searchTeleconsultation = event => {
    search.value = event.target.value;
    if(event.target.value.length >= 5){
        store.dispatch("teleconsultations/fetchTeleconsultations", { page: 1, page_size: page_size.value, search: event.target.value });
    }
    if(event.target.value.length == 0){
        store.dispatch("teleconsultations/fetchTeleconsultations", { page: 1, page_size: page_size.value, search: search.value });
    }
}

const isViewContent = ref(false)
const viewMore = () => (isViewContent.value = true)

const getAlerte = uuid => {
    router.push({name: 'alertes', query: {'uuid': uuid}})
}




onMounted(() => {
    store.dispatch("teleconsultations/fetchTeleconsultations")
})

</script>