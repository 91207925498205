import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Pagination } from "@/_types"
import examen_clinicService from "@/_services/examen_clinicService";
import { useToast } from 'vue-toast-notification';

@Module({namespaced: true})
export default class ExamenClinicModule extends VuexModule
{
    examen_clinic : Pagination | null = null;

    $toast = useToast();

    loadingStatus: boolean = false;

    //getters loading
    get gloadingStatus(){
        return this.loadingStatus
    }

    // getters
    get gExamen_clinic(){
        return this.examen_clinic
    }

    @Mutation
    mloadingStatus(newStatus: boolean){
        this.loadingStatus = newStatus
    }

    //mutations
    @Mutation
    setExamen_clinic(examen_clinic: Pagination){
        this.examen_clinic = examen_clinic
    }

    //actions
    @Action
    async getExamen_clinic(page = 1 ){
        examen_clinicService.getExamen_clinics(page)
        .then((res: Pagination) => {
            // console.log("examen_clinic", res.data)
            this.context.commit("setExamen_clinic", res.data);
        })
        .catch((error : any) => {
             console.log("error examen_clinic", error);
        });
    }

    @Action
    async searchExamenClinics(element = {search: "", page: 1} ){
        examen_clinicService.searchExamen(element.search, element.page)
        .then((res: Pagination) => {
            // console.log("examen_clinic", res.data)
            this.context.commit("setExamen_clinic", res.data);
        })
        .catch((error : any) => {
             console.log("error examen_clinic", error);
        });
    }

    @Action
    async createdExamenClinic(examen_clinic: any | {}){
        this.context.commit('mloadingStatus', true)
        await examen_clinicService.createExamen_clinic(examen_clinic)
                 .then((res: any) => {
                    this.context.commit('mloadingStatus', false)
                    let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, examen_cliniques: res.data.data};
                    this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
                    this.$toast.success('Examen Clinic créé avec succès', { position: "top-right" })
                 })
                .catch((error : any) => {
                    this.context.commit('mloadingStatus', false)
                    console.log("error examen_clinic", error);
                });
    }

    @Action
    async deleteExamenClinique(examen_clinique = {relation_id:0, examen_clinic_id: 0, relation: ""}){
        this.context.commit('mloadingStatus', true)
        await examen_clinicService.deleteExamen_clinic(examen_clinique.relation_id, examen_clinique.examen_clinic_id, examen_clinique.relation)
                 .then((res: any) => {
                    this.context.commit('mloadingStatus', false)
                    let examen_clinic = this.context.rootState.teleconsultations.teleconsultation.examen_cliniques.filter((item: any) => item.id != res.data.data.id);
                    let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, examen_cliniques: examen_clinic};
                    this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
                    this.$toast.success('Examen Clinic supprimé avec succès', { position: "top-right" })
                 })
                .catch((error : any) => {
                    this.context.commit('mloadingStatus', false)
                    console.log("error examen_clinic", error);
                });
    }
}