<template lang="">
    <div class="row">
        <div class="col-md-12">
            <Create-Rendez-Vous :teleconsultation_id="teleconsultation.id"
            :patient_id="patient_id" :creator="creator" :etablissement_id="etablissement_id"/>
            <div class="table-responsive">
                <table class="table caption-top">
                    <caption>Liste des examens complémentaires</caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Motifs</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <div class="position-absolute top-50 start-50" v-if="loader">
                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                    </div>
                    <tbody v-if="!loader">
                        <tr v-for="(rdv, index) in teleconsultation.rendez_vous" :key="`rdv${index}`">
                            <td>{{ index+1 }}</td>
                            <td>{{ moment(rdv?.date).format("YYYY-MM-DD HH:mm") }}</td>
                            <td v-html="rdv?.motifs"></td>
                            <td class="d-flex">
                                <span class="rounded-circle border p-2 cursor-pointer btn-delete"
                                @click="deleteRdv(rdv.id)"><i class="fa-solid fa-trash red"></i></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, toRefs, computed, inject } from 'vue';
import moment from 'moment';
import CreateRendezVous from './CreateRendezVous.vue';
import Modal from './../Modal.vue';
import { useStore } from 'vuex'

const store = useStore();
const props = defineProps({
  teleconsultation: {},
})

const { teleconsultation } = toRefs(props)

const patient_id = store.state.teleconsultations.teleconsultation.patient.user_id
const etablissement_id = store.state.teleconsultations.teleconsultation.etablissements[0].id
const creator = store.state.teleconsultations.teleconsultation.creator

const swal = inject('$swal');
const deleteRdv = (rdv_id) => {
    swal.fire({
        title: 'Voulez-vous vraiment supprimer ce rendez-vous?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
            store.dispatch('teleconsultations/deleteRendezVous', rdv_id).then(() => {
                // swal.fire('Saved!', '', 'success');
            })
        } else if (result.isConfirmed) {
            swal.fire('Changes are not saved', '', 'info')
        }
    })
}
const loader = computed(() => store.state.teleconsultations.loadingStatus)
</script>