<template lang="">
    <div>
        <user-info />
        <router-view class="mt-4"/>
    </div>
</template>
<script>
import UserInfo from '@/components/organisms/UserInfo.vue';
export default {
    components: {
        UserInfo
    }
}
</script>