import { Allergie } from './../../../_types/allergie';
import { Alerte } from '@/_types/alerte';
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Teleconsultation, Pagination } from "@/_types"
import TeleconsultationService from "@/_services/TeleconsultationService";
import { computeSize } from "bootstrap-vue-3/dist/components/BAvatar/BAvatar.vue";
import moment from "moment";
import { useToast } from 'vue-toast-notification';
import router from "@/router";

@Module({
  namespaced: true
})
export default class TeleconsultationsModule extends VuexModule {

  teleconsultations : Array<Teleconsultation> = [];
  teleconsultation : Teleconsultation | null = null;
  paginations : Pagination | null = null;
  alerte : Alerte | null = null;
  lien : string | null = null;
  allergies: [] | null = [];
  antecedents: [] | null = [];
  rendez_vous: [] | null = null;

  loadingStatus: boolean = false;

  $toast = useToast();

  get gteleconsultations()  {
    return this.paginations?.data.data
  }
  get gteleconsultation()  {
    return this.teleconsultation
  }
  get gpaginations()  {
    return this.paginations
  }

  get gRendezVous()  {
    return this.rendez_vous
  }

  get gAntecedents(){
    return this.antecedents
  }

  get gAllergies(){
    return this.allergies
  }

  get gAlerte()  {
    return this.alerte
  }

  get gLien()  {
    return this.lien
  }


  //getters loading
  get gloadingStatus(){
    return this.loadingStatus
  }

//-------------------------- Mutation teleconsultation
  @Mutation
  getTeleconsultationsHandler(paginations: Pagination) {
    this.paginations = paginations
  }

  @Mutation
  getAlerteHandler(alerte: Alerte) {
    this.alerte = alerte
  }

  @Mutation
  getTeleconsultationHandler(teleconsultation: Teleconsultation) {
    this.teleconsultation = teleconsultation
  }

  @Mutation
  deleteTeleconsultationHandler(teleconsultation: Teleconsultation) {
    this.teleconsultations = this.teleconsultations.filter(item => item.id != teleconsultation.id);
  }

  @Mutation
  mloadingStatus(newStatus: boolean){
    this.loadingStatus = newStatus
  }

  @Mutation
  setRendezVous(RendezVous: null){
    this.rendez_vous = RendezVous
  }

  @Mutation
  getAllergiesHandler(allergies: null | []){
    this.allergies = allergies
  }

  @Mutation
  getAntecedentsHandler(antecedents: null | []){
    this.antecedents = antecedents
  }

  @Mutation
  getLienHandler(lien: string){
    this.lien = lien
  }



/*   @Mutation
  getTeleconsultationMotifsHandler(teleconsultation: Teleconsultation, motifs: Array<any>){
    this.teleconsultation = {...teleconsultation, motifs: motifs};
  } */



//---------------------------------------- Actions teleconsultation
  @Action
  fetchTeleconsultations(paginate = {page: 1, page_size: 10, search: ""}) {
    this.context.commit('mloadingStatus', true)

    TeleconsultationService.getTeleconsultations(paginate.page, paginate.page_size, paginate.search).then((res: Pagination) => {
      this.context.commit('getTeleconsultationsHandler', res.data)
      this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })

  }

  @Action
  getTeleconsultation(teleconsultation: any){
    this.context.commit('mloadingStatus', true)

    TeleconsultationService.getTeleconsultation(teleconsultation).then((res: any) => {
      this.context.commit('getTeleconsultationHandler', res.data.data)
      this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })
  }

  @Action
  fetchAlerte(element = {medecin_id: 0, patient_id: 0}) {
    this.context.commit('mloadingStatus', true)
    TeleconsultationService.getAlerte(element.medecin_id, element.patient_id).then((res: any) => {
      this.context.commit('getAlerteHandler', res.data)
      this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })
  }

  @Action
  fetchAllergies(patient_id: number) {
    this.context.commit('mloadingStatus', true)
    TeleconsultationService.getAllergies(patient_id).then((res: any) => {
      this.context.commit('getAllergiesHandler', res.data)
      this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })
  }

  @Action
  fetchAntecedents(patient_id: number) {
    this.context.commit('mloadingStatus', true)
    TeleconsultationService.getAntecedents(patient_id).then((res: any) => {
      this.context.commit('getAntecedentsHandler', res.data)
      this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })
  }

  @Action
  fetchTeleconsultation(teleconsultation: Teleconsultation) {
    this.context.commit('getTeleconsultationHandler', teleconsultation)
  }

/*   @Action
  fetchTeleconsultationMotifs(motifs: Array<any>) {
    this.context.commit('getTeleconsultationMotifsHandler', motifs)
  } */


  @Action({ rawError: true })
  createdTeleconsultation(teleconsultation: any) {

    this.context.commit('mloadingStatus', true)

    let antecedents: any = [];
    if(localStorage.getItem('antecedent') != null){
      antecedents = JSON.parse(localStorage.getItem('antecedent') || "");
    }

    const telecons = {
      creator: teleconsultation.creator,
      patient_id: teleconsultation.patient_id,
      allergie_id: teleconsultation.allergies.map((item: any) => item.id == null ? "item__"+item.description : item.id),
      cat: teleconsultation.cat,
      date_heure: moment(teleconsultation.dateNow).format('YYYY-MM-DD HH:mm'),
      date_rdv: moment(teleconsultation.date).format('YYYY-MM-DD HH:mm'),
      examen_complementaire_id: teleconsultation.examens.map((item: any) => item.id == null ? "item__"+item.description : item.id),
      examen_clinique_id: teleconsultation.examens.map((item: any) => item.id),
      motif_rdv: teleconsultation.motif,
      rendezVous: teleconsultation.rendezVous,
      anamnese: teleconsultation.anamnese,
      motif_id: [teleconsultation.motifs.id, ...teleconsultation.motifs_secondaires.map((item: any) => item.id == null ? "item__"+item.description : item.id)],
      etablissement_id: teleconsultation.etablissement.id,
      type_teleconsultation_id: teleconsultation.typesTc.id,
      anamnese_id : teleconsultation.anamnese_id.id,
      code_icd: teleconsultation.maladies.map((item: any) => item.code),
      name: teleconsultation.maladies.map((item: any) => item.title),

      description: antecedents.map((item: any) => item.description),
      type_id: antecedents.map((item: any) => item.type.id),
      date: antecedents.map((item: any) => moment(item.date).format('YYYY-MM-DD HH:mm')),
      //dossier_medical_id: teleconsultation.dossier_medical_id,
      description_diagnostic: teleconsultation.description_diagnostic,
      ordonnance_description: teleconsultation.ordonnance_description,
      description_examen_clinique: teleconsultation.description_examen_clinique,
    }

    TeleconsultationService.createdTeleconsultation(telecons).then((res: any) => {
      this.context.commit("teleconsultations/getTeleconsultationHandler", res.data.data, { root: true });
      this.$toast.success('Téléconsultation crée avec succès', { position: "top-right" })
      this.context.commit('mloadingStatus', false)
      router.push({name: 'teleconsultation-affichage', params: {uuid: res.data.data?.uuid}})
    }).catch((error: any) => {
      this.$toast.error('Echec téléconsultation', { position: "top-right" })
      console.log("error ", error.response.data);
      this.context.commit('mloadingStatus', false)
    })
  }

  @Action
  getRendezVous(){
    TeleconsultationService.getRendezVous().then((res: any) => {
      this.context.commit('setRendezVous', res.data.data?.data)
    }).catch((error: any) => {
      console.log("error ", error.response.data)
    })
  }

  @Action
  async createdRendezVous(rdv: any | {}){
    this.context.commit('mloadingStatus', true)
    await TeleconsultationService.createRendezVous(rdv).then((res: any) => {
      this.context.commit('mloadingStatus', false)
      let rendez_vous = this.context.rootState.teleconsultations.teleconsultation.rendez_vous;
      let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, rendez_vous: [res.data.data, ...rendez_vous] };
      this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
      this.$toast.success('Rendez-vous ajouté', { position: "top-right" })
    }).catch((error: any) => {
      console.log("error ", error.response.data)
      this.context.commit('mloadingStatus', false)
    })
  }

  @Action
  async deleteRendezVous(rdv_id: number){
    this.context.commit('mloadingStatus', true)
    await TeleconsultationService.deleteRendezVous(rdv_id).then((res: any) => {
      this.context.commit('mloadingStatus', false)
      let rdv = this.context.rootState.teleconsultations.teleconsultation.rendez_vous?.filter((item: any) => item.id != res.data.data?.id);
      let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, rendez_vous: rdv };
      this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
      this.$toast.success('Rendez-vous supprimé', { position: "top-right" })
    }).catch((error: any) => {
      console.log("error ", error)
    })
  }

  @Action
  async printTeleconsultation(teleconsultation_id: number){
    this.context.commit('mloadingStatus', true)
    await TeleconsultationService.printTeleconsultation(teleconsultation_id).then((res: any) => {
      this.context.commit('getLienHandler', res.data);
      this.context.commit('mloadingStatus', false)
      this.$toast.success('Téléconsultation imprimée', { position: "top-right" })
    }).catch((error: any) => {
      console.log("error ", error)
    })
  }

  @Action
  async delete(teleconsultation_id: number){
    this.context.commit('mloadingStatus', true)
    await TeleconsultationService.deletedTeleconsultation(teleconsultation_id).then((res: any) => {
      this.context.commit('mloadingStatus', false)
      this.$toast.success('Téléconsultation supprimé avec succès', { position: "top-right" })
    }).catch((error: any) => {
      console.log("error ", error)
    })
  }


}
