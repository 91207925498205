import { defineStore } from "pinia";
import BulletinAnalyseService from "@/_services/BulletinAnalyseService"; 
import { useToast } from 'vue-toast-notification';
import router from "@/router";


export interface Bulletin {
    id: number;
    libelle: string;
    data: any;
}


export const useBulletin = defineStore({
    id: 'analyse_medical',
    state: () => ({
        $toast: useToast(),
        // State
        //
        bulletins: {
            data: []
        } as Bulletin,
        bulletin: {} as Bulletin[] | null,
        loading: false
    }),
    getters: {
        getBulletin() : Object{
            return this.bulletins;
        },
        getOneBulletin() : Object | any {
            return this.bulletin;
        },
    },
    actions: {
        // Actions

        async fetchBulletins(paginate = {page: 1, page_size: 5, search: ""}) {
            this.loading = true
            await BulletinAnalyseService.getBulletins(paginate.page, paginate.page_size, paginate.search)
                .then((res: any) => {
                    this.loading = false
                    this.bulletins = res.data.data
                })
                .catch((err:any) => console.log(err))
        },

        async getBulletinId(uuid: any) {
            this.loading = true
            await BulletinAnalyseService.getBulletin(uuid).then((res: any) => {
                this.loading = false
                this.bulletin = res.data.data
            })
            .catch((err:any) => console.log(err));
            // this.bulletins = this.bulletins.filter(bulletin => bulletin.id == id);
        },

        async deleteBulletin(id: any) {
            this.loading = true
            await BulletinAnalyseService.deleteBulletin(id).then((res: any) => {
                this.loading = false
                this.$toast.warning('Bulletin supprimée avec succès', { position: 'top-right' });
            })
            .catch((err:any) => {
                this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                console.log(err)
            });
        },

        async printBulletin(id: any) {
            await BulletinAnalyseService.printBulletin(id).then((res: any) => {
                console.log(id);
                // this.fetchBulletins({page: 1, page_size: 5})
            })
            .catch((err:any) => console.log(err));
        },

        async createBulletin(bulletin: any) {
            let ExamenStorage = JSON.parse(localStorage.getItem('examen') || '[]')
            let ExamenId = ExamenStorage.map((item: any) => item.id)
            const bulletins = {
                ligne_temps_id: bulletin.evenement_medical.id.id,
                niveau_urgence_id: bulletin.niveau_urgence.id.id,
                option_financement_id: bulletin.option_financement.id,
                etablissement_id: bulletin.etablissement.id.id,
                medecin_id: bulletin.medecin_id,
                patient_id: bulletin.patient_id,
                date_heure: bulletin.date_heure.id,
                raison_prescription_id: bulletin.raison_prescription.id.id,
                renseignement_clinique: bulletin.description.id,
                examens: ExamenId,
            }
            this.loading = true
            await BulletinAnalyseService.createBulletin(bulletins)
                .then((res: any) => {
                    this.loading = false
                    this.bulletins = res.data.data
                    this.$toast.success('Bulletin créé avec succès', { position: 'top-right' })
                    localStorage.removeItem('examen')
                    router.push({name: 'bulletin-affichage', params: {uuid: res.data.data?.uuid}})
                })
                .catch((err:any) => {
                    this.loading = false
                    this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                    console.log(err)
                })
        },

        async updateBulletin(uuid:string, bulletin: any) {
            let ExamenStorage = JSON.parse(localStorage.getItem('examen') || '[]')
            let ExamenId = ExamenStorage.map((item: any) => item.id)
            const bulletins = {
                ligne_temps_id: bulletin.evenement_medical.id.id,
                niveau_urgence_id: bulletin.niveau_urgence.id.id,
                option_financement_id: bulletin.option_financement.id,
                etablissement_id: bulletin.etablissement.id.id,
                medecin_id: bulletin.medecin_id,
                patient_id: bulletin.patient_id,
                date_heure: bulletin.date_heure.id,
                raison_prescription_id: bulletin.raison_prescription.id.id,
                renseignement_clinique: bulletin.description.id,
                examens: ExamenId,
            }
            this.loading = true
            await BulletinAnalyseService.updateBulletin(uuid, bulletins)
                .then((res: any) => {
                    this.loading = false
                    this.bulletins = res.data.data
                    this.$toast.success('Bulletin modifié avec succès', { position: 'top-right' })
                    localStorage.removeItem('examen')
                    router.push({name: 'bulletin-affichage', params: {uuid: res.data.data?.uuid}})
                })
                .catch((err:any) => {
                    this.$toast.error('Une erreur est survenue, Veuillez réesseyer', { position: 'top-right' })
                    console.log(err)
                })
        }
    }
});