<template>
    <div>
      <!-- <div class="step-header">
        <h2>{{ steps[currentStepIndex].title }}</h2>
      </div> -->
      <div class="row mt-4">
        <div class="steppers col-md-3">
          <div v-for="(step, index) in steps" :key="index" :class="{ active: currentStepIndex === index, completed: index < currentStepIndex }">
              <div class="step-content row px-5 cursor-pointer" @click="goToCurrentIndexClick(index)">
                  <div class="step-number"><span>{{ index + 1 }}</span></div>
                  <small class="step-title col d-none d-md-block">{{ step.title }}</small>
              </div>
          </div>
        </div>
        <div class="step-content col-md ">
          <slot :name="steps[currentStepIndex].slotName"></slot>
          <div class="step-buttons d-flex mt-4">
              <button class="btn default d-flex align-items-center" type="button" @click="prevStep" v-if="currentStepIndex > 0"><i class="fa-solid fa-arrow-left mx-1"></i> Précédent</button>
              <div class="w-100 d-flex justify-content-end">
                  <button class="btn mx-1" :disabled="!validations" :class="!validations ? 'bg-gray-200 cursor-drop' : 'btn-dark shadow'" type="button" @click="nextStep" v-if="!(currentStepIndex === steps.length - 1)">Suivant</button>
                  <button @click="AvoidSubmitForm" type="button" v-if="currentStepIndex === steps.length - 1" class="btn btn-outline-defult">Annuler</button>
                  <button :disabled="Status" :class="[Status ? 'bg-gray-200' : 'btn-primary shadow']" class="btn mx-1 d-flex align-items-center" type="button" @click="submitForm" v-if="currentStepIndex === steps.length - 1">
                    <b-spinner v-if="Status" variant="default" label="Spinning"></b-spinner>
                    <span class="mx-1">Soumettre</span>
                  </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  
  export default {
    props: {
      steps: {
        type: Array,
        required: true,
      },
      validations: {
        type: Boolean,
        default: false,
        required: false,
      },
      submitForm: {
        type: Function,
        required: true,
      },
      Status: {
        type: Boolean,
        default: false,
        required: false,
      },
      AvoidSubmitForm: {
        type: Function,
        required: false,
      },
    },
    setup(props) {
      const currentStepIndex = ref(0)

      const goToCurrentIndexClick = (index) => {
        if(props.validations){
            currentStepIndex.value = index
        }else{
          currentStepIndex.value = 0
        }
      }

      const prevStep = () => {
        if (currentStepIndex.value > 0) {
            currentStepIndex.value--
        }
      }
  
      const nextStep = () => {
        if(props.validations){
            if (currentStepIndex.value < props.steps.length - 1) {
            currentStepIndex.value++
            }
        }
      }
  
      return {
        currentStepIndex,
        prevStep,
        nextStep,
        goToCurrentIndexClick
      }
    },
  }
  </script>