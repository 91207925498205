<template lang="">
    <div>
        <div class="d-flex justify-content-end">
                <!-- <div class="p-2">
                    Envoyer par
                </div>
                <div class="p-2">
                    <ShareNetwork
                        network="telegram"
                        :url="store.getOnePrescription?.pdf"
                        :title="'Hello, consulter la prescription *#'+store.getOnePrescription?.id+'* du patient *'+store.getOnePrescription?.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-brands fa-telegram fa-2x" style="color: #0088cc;"></i>
                    </ShareNetwork>
                </div>
                <div class="p-2">
                    <ShareNetwork
                        network="whatsapp"
                        :url="store.getOnePrescription?.pdf"
                        :title="'Hello, consulter la prescription *#'+store.getOnePrescription?.id+'* du patient *'+store.getOnePrescription?.patient.user.name+'*'"
                        description="Cliquez sur le lien ci-dessus">
                        <i class="fa-brands fa-whatsapp fa-2x" style="color: #128C7E;"></i>
                    </ShareNetwork>
                </div> -->
            </div>
            <div class="d-flex mt-4" v-if="store.loading">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin m-auto"/>
            </div>
            <div v-if="!store.loading">
                <div class="mt-3">
                    <fieldset class="form-group border p-2 default">
                        <legend class="default float-none w-auto px-2 h2">Documentations</legend>
                        <div class="boxDetails w-100">
                            <div class="row pt-2">
                                <div class="col-md-6">
                                    <p class="mb-4">
                                        <span class="fw-bold my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Object :</span> <div class="pt-1" >{{store.getOneEmailsms.subject}}</div>
                                    </p>
                                    <p class="mb-4">
                                        <span class="fw-bold my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Message : </span> <div class="pt-1"><span v-html="store.getOneEmailsms.message_body"></span></div>
                                    </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-4" id="store.getOneEmailsms.user_email">
                                        <span class="fw-bold m-1 my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-service.png" alt="" class="img-alert"/>Emails des utilisateurs : </span>
                                        <span class="mt-1 mx-2 d-flex" >{{store.getOneEmailsms.user_email}}</span>
                                    </p>
                                    <p class="mb-4">
                                        <span class="fw-bold my-2"><img src="@/assets/icons/DetailTeleconsultation/medical-records.png" alt="" class="img-alert"/>Date :  </span>
                                        <span >{{moment(store.getOneEmailsms.created_at).format('DD-MM-YYYY')}}</span>
                                    </p>
                                    <!-- {{moment(store.getOnePrescription?.date_heure).format('DD-MM-YYYY')}} -->
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
    </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { ref, reactive, inject,onMounted, computed, watch } from 'vue';
import { useEmailsms } from "@/_storeV2/email-sms";
import moment from "moment";
import { useRouter, useRoute } from 'vue-router';

const store = useEmailsms();
const xstore = useStore();
const router = useRouter();
const route = useRoute();
onMounted(() =>{
    
    store.getEmailsmsId(route.params.uuid)
})
</script>
<style lang="">
    
</style>