<template lang="">
    <div>
        <button type="button" @click="viewExamen" class="btn border d-flex align-items-center gap-1 m-2"><i class="fa-solid fa-plus"></i> Ajouter</button>
        <Modal v-model="newExam" title="Nouveau Examen Complementaire" bgcolor="#172b4d" colorTitle="white"  width="lg">
           <div class="my-3 d-flex justify-content-center" v-if="loader">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
            </div>
            <div v-if="!loader">
                <Examen-Complementaire-Form :state="state" :v$="v$"/>
                <button class="btn btn-primary" @click="createExamenComplementaire">Enregistrer</button>
           </div>
        </Modal>
    </div>
</template>
<script setup>
import ExamenComplementaireForm from "./ExamenComplementaireForm.vue";
import Modal from './../Modal.vue';
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps({
    teleconsultation_id: {
        type: Number,
        required: true
    },
})

const rules = computed(() => {
    return {
        description: { required }
    }
})

const state = reactive({
    description: [],
    teleconsultation_id: props.teleconsultation_id,
})

const v$ = useVuelidate(rules, state);

const newExam = ref(false);

const viewExamen = () =>{
    newExam.value = true
}

const createExamenComplementaire = () =>{
    v$.value.$validate()
    if(!v$.value.$error){
        store.dispatch('examen/createdExamenComplementaire', {fr_description : state.description.map(item => item.id), teleconsultation_id: props.teleconsultation_id}).then((res)=>{
            state.description = [];
            newExam.value = false;
        })
    }
}

const loader = computed(() => store.state.examen.loadingStatus)
</script>