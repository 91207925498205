<template lang="">
    <div>
        <button type="button" @click="viewAllergie" class="btn border d-flex align-items-center gap-1 m-2"><i class="fa-solid fa-plus"></i> Ajouter</button>
        <Modal v-model="newAllergie" title="Nouvelles Allergies" bgcolor="#172b4d" colorTitle="white"  width="lg">
            <div class="d-flex justify-content-center" v-if="loader">
                <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
            </div>
            <div v-if="!loader">
                <Dynamic-Input :form="state.collection" :v$="v$" title="Allergie"/>
                <button class="btn btn-primary" @click="createAllergie">Enregistrer</button>
           </div>
        </Modal>
    </div>
</template>
<script setup>
import DynamicInput from '@/components/organisms/DynamicInput.vue';
import Modal from './../Modal.vue';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'

const props = defineProps({
    teleconsultation_id: {
        type: Number,
        required: true
    },
})

const store = useStore();

const newAllergie = ref(false);

const rules = computed(() => {
    return {
        collection: {
            $each: helpers.forEach({
                desciption: { required },
                date: { required }
            })
        }
    }
})
const state = reactive({
    collection: [{ description: [], date: []},]
})

const v$ = useVuelidate(rules, state);

const viewAllergie = () =>{
    newAllergie.value = true
}

const createAllergie = () => {
    v$.value.collection.$validate()
    if(!v$.value.collection.$error){
        store.dispatch("allergie/createAllergie", {collection: state.collection, teleconsultation_id: props.teleconsultation_id}).then(() =>{
            newAllergie.value = false
            state.collection = [{ description: [], date: []},]
        })
    }else{
        console.log(state.collection)
    }
}

const loader = computed(() => store.state.allergie.loadingStatus)
</script>