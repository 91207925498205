<template lang="">
    <div class="row">
        <div class="col-md-12 default">
            <Create-Anamnese :teleconsultation_id="teleconsultation.id"/>
            <div class="table-responsive">
                <table class="table caption-top default">
                    <caption>Liste des anamnèses</caption>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Anamnese</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <div class="position-absolute top-50 start-50" v-if="loader">
                        <img src="@/assets/icons/Spin.svg" alt="" class="btn-spin"/>
                    </div>
                    <tbody v-if="!loader">
                        <tr v-for="(anamnese, index) in teleconsultation.anamneses" :key="`anamnese${index}`">
                            <td>{{ index+1 }}</td>
                            <td v-html="anamnese?.fr_description"></td>
                            <td v-html="convertJeson(anamnese?.pivot?.data)"></td>
                            <td class="d-flex"> 
                                <!-- <UpdateAnamnese :selectAnamnese="selectAnamnese(anamnese)"/> -->
                                <span class="rounded-circle border p-2 cursor-pointer btn-delete"
                                @click="deleteAnamnese(anamnese.id)"><i class="fa-solid fa-trash red"></i></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, toRefs, computed, onMounted, reactive, inject } from 'vue';
import moment from 'moment';
import VueMultiselect from 'vue-multiselect';
import CreateAnamnese from './CreateAnamnese.vue';
import UpdateAnamnese from './UpdateAnamnese.vue';
import Modal from './../Modal.vue';

import { useStore } from 'vuex'
const store = useStore();

const props = defineProps({
  teleconsultation: {},
})
const swal = inject('$swal');
const deleteAnamnese = (anamnese_id) =>{
    swal.fire({
        title: 'Voulez-vous vraiment supprimer cette amnanèse?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Annuler',
        denyButtonText: `Supprimer`,
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isDenied) {
            store.dispatch("anamnese/deleteAnamnese", anamnese_id = anamnese_id).then(() => {
                // swal.fire('Saved!', '', 'success');
            })
        } else if (result.isConfirmed) {
            swal.fire('Changes are not saved', '', 'info')
        }
    })
}
const selectAnamnese = (anamnese) => {
    console.log("anamnese", anamnese)
}
const updateAnamnese = (anamnese_id) => {
    store.dispatch("anamnese/updateAnamnese", anamnese_id = anamnese_id).then(() => {
        // swal.fire('Saved!', '', 'success');
    })
}

const convertJeson = data => {
    if(data != undefined){
        return JSON.parse(data).anamnese
    }
    return ""
}

const { teleconsultation } = toRefs(props)
const loader = computed(() => store.state.anamnese.loadingStatus)
</script>