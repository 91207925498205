import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Pagination } from "@/_types"
import examen_complementaireService from "@/_services/examen_complementaireService";
import { useToast } from 'vue-toast-notification';

@Module({namespaced: true})
export default class ExamenComplementaireModule extends VuexModule
{
    examen : Pagination | null = null;
    $toast = useToast();

    loadingStatus: boolean = false;
    // getters
    get gExamen(){
        return this.examen
    }

    //getters loading
    get gloadingStatus(){
        return this.loadingStatus
    }

    @Mutation
    mloadingStatus(newStatus: boolean){
        this.loadingStatus = newStatus
    }


    //mutations
    @Mutation
    setExamen(examen: Pagination){
        this.examen = examen
    }

    

    //actions
    @Action
    async fetchExamen(page = 1 ){
        examen_complementaireService.getExamen_complementaires(page)
        .then((res: Pagination) => {
            // console.log("examen", res.data)
            this.context.commit("setExamen", res.data);
        })
        .catch((error : any) => {
             console.log("error anamnese",error);
        });
    }

    @Action
    async searchExamens(element = {search: '', page: 1} ){
        examen_complementaireService.searchExamens(element.search, element.page)
        .then((res: Pagination) => {
            this.context.commit("setExamen", res.data);
        })
        .catch((error : any) => {
             console.log("error anamnese",error);
        });
    }

    @Action
    async createdExamenComplementaire(examen_comp: {} | any){
        this.context.commit('mloadingStatus', true)
        await examen_complementaireService.createExamen_complementaire(examen_comp)
                .then((res: any) => {
                    this.context.commit('mloadingStatus', false)
                    let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, examen_complementaires: res.data.data};
                    this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
                    this.$toast.success('Examen Complementaire créé avec succès', { position: "top-right" })
                })
                .catch((error: any) =>{
                    this.context.commit('mloadingStatus', false)
                    console.log("error examen complementaire", error)
                })
    }

    @Action
    async deleteExamenComplementaire(examen_comp= { relation_id: 0, examen_complementaire_id: 0, relation: ""}){
        this.context.commit('mloadingStatus', true)
        await examen_complementaireService.deleteExamen_complementaire(examen_comp.relation_id, examen_comp.examen_complementaire_id, examen_comp.relation)
                .then((res: any) => {
                    this.context.commit('mloadingStatus', false)
                    let examen = this.context.rootState.teleconsultations.teleconsultation.examen_complementaires.filter((item: any) => item.id != res.data.data.id)
                    let teleconsultation = {...this.context.rootState.teleconsultations.teleconsultation, examen_complementaires: examen};
                    console.log("telec", teleconsultation)
                    this.context.commit("teleconsultations/getTeleconsultationHandler", teleconsultation, { root: true });
                    this.$toast.success('Examen Complementaire avec succès', { position: "top-right" })
                })
                .catch((error: any) =>{
                    console.log("error examen complementaire", error)
                    this.$toast.error(error)
                })
    }
}