import Axios from '../api';
import { Pagination } from '@/_types/pagination';
import { Anamnese, AnamneseCreate } from '@/_types';


class AnamneseService {
    path: string;

    constructor() {
        this.path = '/anamneses';
    }

    async getAnamneses(page = 1): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}`);
    }

    async searchEAnamnese(page = 1, search: string): Promise<Pagination> {
        return await Axios.get(`${this.path}?page=${page}&search=${search}`);
    }

    async createdAnamnese(anamnese: AnamneseCreate | any): Promise<AnamneseCreate> {
        return await Axios.post(this.path, anamnese);
    }

    async deleteAnamnese(id: number): Promise<void> {
        return await Axios.delete(`${this.path}/${id}`);
    }
}

export default new AnamneseService;