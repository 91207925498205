import Axios from '../api';

class TimelineService {
    path: string;

    constructor() {
        this.path = '/timelines';
    }

    async getTimeline(patient_slug: number, patient: string): Promise<any> {
        return await Axios.get(`${this.path}/${patient_slug}/${patient}`);
    }
}

export default new TimelineService();