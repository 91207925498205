import BigBlueButtonService from "@/_services/BigBlueButtonService";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";


@Module({
  namespaced: true
})
export default class BigblueButtonModules extends VuexModule {

  urlModerator : string = "";
  urlPatient : string = "";
  loadingStatus: boolean = false;


  get url_moderator()  {
    return this.urlModerator
  }
  get url_patient()  {
    return this.urlPatient
  }


//-------------------------- Mutation BigblueButton

  @Mutation
  getUrlModeratorHandler(urlModerator: string) {
    this.urlModerator = urlModerator
  }

  @Mutation
  getUrlPatientHandler(urlPatient: string) {
    this.urlPatient = urlPatient
  }

  @Mutation
  mloadingStatus(newStatus: boolean){
    this.loadingStatus = newStatus
  }




//---------------------------------------- Actions BigblueButton

  @Action
  createMetting(user_id: number) {
    this.context.commit('mloadingStatus', true)
    BigBlueButtonService.createMetting(user_id).then((res: any) => {
        this.context.commit('getUrlModeratorHandler', res.data)
        this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error)
    })
  }

  @Action
  joinMetting(user_id: number) {
    this.context.commit('mloadingStatus', true)
    BigBlueButtonService.joinMetting(user_id).then((res: any) => {
        this.context.commit('getUrlPatientHandler', res.data)
        this.context.commit('mloadingStatus', false)
    }).catch((error: any) => {
      console.log("error ", error)
    })
  }

  @Action
  seturl(url ={moderator: "", patient: ""}) {
    this.context.commit('getUrlModeratorHandler', url.moderator)
    this.context.commit('getUrlPatientHandler', url.patient)
  }
}
