/**
 * importation des modules nécessaires
 */
import router from '@/router'
import store from '@/store';

export function authGuard(to : any){
    let token = localStorage.getItem('ms-token');
    const slug: string = to.query.slug;
    const ms = to.query.ms;
    if(slug != null){
        store.dispatch("users/UpdatePatientSlug", slug)
    }

    if(ms != null){
        localStorage.clear()
        store.dispatch("users/UpdateToken", ms)
        const id = to.query.slug
        const associations: string = "user"
        token = localStorage.getItem('ms-token');
        store.dispatch("patient/fetchPatient", { id, associations })
        router.push({
            name: 'AddTeleconsultation',
            query: { slug: slug },
            params: {slug: slug}
        })
        
    }

    if(token){
        return true
    }

    router.push('/login');
};

// vuex